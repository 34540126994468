<template>
  <form-view
    :url="`/company/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :custom-validator="customValidator"
    :before-submit="beforeSubmit"
  >
    <div class="section-title-row">
      <div class="title">基础信息</div>
      <div class="line"></div>
    </div>
    <el-row type="flex" :gutter="25">
      <el-col :sapn="8">
        <el-col :span="24">
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="dataForm.companyName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="经理" prop="legalPerson">
            <el-input v-model="dataForm.legalPerson"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="经理电话" prop="legalPhone">
            <el-input type="number" v-model="dataForm.legalPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-col>

      <el-col :sapn="8">
        <el-col :span="24">
          <el-form-item label-width="82px" label="公司简称" prop="companyAbbr">
            <el-input v-model="dataForm.companyAbbr"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label-width="82px" label="车队长" prop="carCaptainName">
            <el-input v-model="dataForm.carCaptainName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label-width="82px" label="车队长电话" prop="carCaptainPhone">
            <el-input type="number" v-model="dataForm.carCaptainPhone"></el-input>
          </el-form-item>
        </el-col>

      </el-col>
      <el-col :sapn="8">
        <el-col :span="24">
          <el-form-item label-width="82px" label="负责人" prop="principalName">
            <el-select filterable v-model="dataForm.headUserId">
              <el-option v-for="s in principalList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label-width="82px" label="公司地址" prop="companyAddress">
            <el-input :rows="4" type="textarea" v-model="dataForm.companyAddress"></el-input>
          </el-form-item>
        </el-col>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">营业执照信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="信用代码" prop="businessLicenseCreditCode">
          <el-input v-model="dataForm.businessLicenseCreditCode"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="82px"  label="名称" prop="businessLicenseName">
          <el-input v-model="dataForm.businessLicenseName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="类型" prop="businessLicenseType">
          <el-input v-model="dataForm.businessLicenseType"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="住所" prop="businessLicenseAddress">
          <el-input v-model="dataForm.businessLicenseAddress"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="82px"  label="法定代表人" prop="businessLicenseLegalPerson">
          <el-input v-model="dataForm.businessLicenseLegalPerson"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="注册资本" prop="businessLicenseCapital">
          <el-input v-model="dataForm.businessLicenseCapital"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="成立日期" prop="businessLicenseFoundDate">
          <el-date-picker v-model="dataForm.businessLicenseFoundDate"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="82px"  label="营业期限" prop="businessLicenseTerm">
          <el-input v-model="dataForm.businessLicenseTerm"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="经营范围" prop="businessLicenseScope">
          <el-input type="textarea" v-model="dataForm.businessLicenseScope"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="6">
        <div class="license-area license-file">
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addLicenseImage($event, 'businessLicenseFile')"
              @remove="removeLicenseImage('businessLicenseFile')"
              :images="dataForm.businessImages || []"
              no-title
            ></image-uploader>
            <div class="title">营业执照附件</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">合同</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="合同编号" prop="contractNumber">
          <el-input v-model="dataForm.contractNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="开始时间" prop="contractTermStartDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.contractTermStartDate"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="结束时间" prop="contractTermEndDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.contractTermEndDate"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <div class="license-area">
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addLicenseImage($event, 'contractAttachment')"
              @remove="removeLicenseImage('contractAttachment')"
              :images="dataForm.contractImages || []"
              no-title
            ></image-uploader>
            <div class="title">合同附件</div>
          </div>
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addLicenseImage($event, 'idCardAttachment')"
              @remove="removeLicenseImage('idCardAttachment')"
              :images="dataForm.idCardImages || []"
              no-title
            ></image-uploader>
            <div class="title">身份证附件</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">附件</div>
      <div class="line"></div>
    </div>
    <el-row>
      <el-col :span="24">
        <image-uploader
          @add="addImage"
          @remove="removeImage"
          :images="dataForm.images || []"
          no-title
        ></image-uploader>
      </el-col>
    </el-row>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import dayjs from 'dayjs'

export default {
  name: 'car-partner-update',

  components: { FormView, ImageUploader },

  data () {
    return {
      loading: false,
      dataForm: {},
      principalList: []
    }
  },

  async created () {
    const userData = await this.$http({
      url: this.$http.adornUrl('/user/list'),
      method: 'post',
      data: {
        userType: 1
      }
    })
    this.principalList = userData.datas
    this.init()
  },

  methods: {
    async init () {
      const id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/company/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          this.dataForm.contractTermStartDate = dayjs(this.dataForm.contractTermStartDate).valueOf()
          this.dataForm.contractTermEndDate = dayjs(this.dataForm.contractTermEndDate).valueOf()
          if (this.dataForm.image) {
            this.dataForm.images = this.dataForm.image.split(',').map(j => ({ url: '/files' + j }))
            this.dataForm.imageList = this.dataForm.images.map(j => j.url.substring('/files'.length))
          } else {
            this.dataForm.images = []
            this.dataForm.imageList = []
          }
          this.dataForm.businessLicenseFile && (this.dataForm.businessImages = [{ url: '/files' + this.dataForm.businessLicenseFile }])
          this.dataForm.contractAttachment && (this.dataForm.contractImages = [{ url: '/files' + this.dataForm.contractAttachment }])
          this.dataForm.idCardAttachment && (this.dataForm.idCardImages = [{ url: '/files' + this.dataForm.idCardAttachment }])
          this.loading = false
        }
      } else {
        this.dataForm = {
          companyName: '',
          companyAbbr: '',
          legalPerson: '',
          legalPhone: '',
          carCaptainName: '',
          carCaptainPhone: '',
          headUserId: '',
          companyAddress: '',
          businessLicenseCreditCode: '',
          businessLicenseName: '',
          businessLicenseType: '',
          businessLicenseAddress: '',
          businessLicenseLegalPerson: '',
          businessLicenseCapital: '',
          businessLicenseFoundDate: '',
          businessLicenseTerm: '',
          businessLicenseScope: '',
          businessImages: [],
          contractNumber: '',
          contractTermStartDate: '',
          contractTermEndDate: '',
          contractImages: [],
          idCardImages: [],
          imageList: []
        }
        this.loading = false
      }
    },

    addImage (path) {
      this.dataForm.imageList.push(path)
    },

    removeImage (url) {
      this.dataForm.imageList.splice(this.dataForm.imageList.indexOf(url), 1)
    },

    customValidator () {
      // if (!this.dataForm.imageList.length) {
      //   this.$opts.alert('必须上传检测文件！')
      //   return false
      // }
      return true
    },

    beforeSubmit (params) {
      params.image = params.imageList.join(',')
      return params
    },

    addLicenseImage (path, name) {
      this.dataForm[name] = path
    },

    removeLicenseImage (name) {
      this.dataForm[name] = ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .license-file {
    justify-content: flex-start;
  }

  .license-area {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
</style>
